import { defineAbility } from "@casl/ability";

export const ability = defineAbility((can) => {
  /* keep rules because issue:https://github.com/stalniy/casl/issues/210 */
  const jsonVuex = localStorage.getItem("vuex");
  if (jsonVuex) {
    const vuex = JSON.parse(jsonVuex);
    let jsonRules: string | null =
      vuex && vuex.authGuard && vuex.authGuard.rules
        ? JSON.stringify(vuex.authGuard.rules)
        : null;
    if (!jsonRules) {
      jsonRules = JSON.stringify([{ actions: "read", subject: "all" }]);
    }

    try {
      const rules = JSON.parse(jsonRules);
      rules.map((rule: any) => {
        return can(rule.actions, rule.subject.replace(/ /g, ""));
      });
    } catch {
      can("read", "all");
    }
  }
});

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import AdminLayout from "@/layouts/admin-layout.vue";
import DashboardLayout from "@/layouts/dashboard-layout.vue";
import LayoutDefaut from "@/layouts/default.vue";
import AuthMiddleware from "@/middlewares/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "home",
    component: () => import("@/layouts/client-layout.vue"),
    children: [
      {
        name: "home",
        path: "home",
        meta: {
          middleware: [AuthMiddleware],
          subject: "courses",
          layoutClass: "home",
        },
        component: () => import("@/pages/home/HomePage.vue"),
      },

      {
        name: "user-detail",
        path: "user-detail",
        component: () => import("@/pages/home/UserDetail.vue"),
      },

      {
        name: "courses-client",
        path: "courses",
        meta: {
          middleware: [AuthMiddleware],
          subject: "courses",
        },
        children: [
          {
            name: "all-courses",
            path: "",
            component: () => import("@/pages/home/AllCourses.vue"),
          },
          {
            name: "course-detail",
            path: ":id",
            meta: {
              layoutClass: "course-detail",
            },
            component: () => import("@/pages/client/course/course-detail.vue"),
          },
          {
            name: "course-learn",
            path: ":id/learn",
            meta: {
              layoutClass: "course-learn",
            },
            component: () => import("@/pages/client/course/course-learn.vue"),
          },
          {
            name: "course-quiz",
            path: ":id/quiz",
            meta: {
              layoutClass: "course-quiz",
            },
            component: () => import("@/pages/client/course/course-quiz.vue"),
          },
          {
            name: "course-final",
            path: ":id/final",
            meta: {
              layoutClass: "course-final",
            },
            component: () => import("@/pages/client/course/course-final.vue"),
          },
        ],
      },

      {
        name: "my-courses",
        path: "/my-courses",
        component: () => import("@/pages/home/MyCourses.vue"),
      },

      {
        name: "gift",
        path: "gift",
        component: () => import("@/pages/client/gift/gift-list.vue"),
      },

      {
        name: "gift-detail",
        path: "gift/:id",
        component: () => import("@/pages/client/gift/gift-detail.vue"),
      },

      {
        name: "get-gift",
        path: "get-gift/:id",
        component: () => import("@/pages/client/gift/get-gift.vue"),
      },
    ],
  },
  
  {
    path: "/admin",
    name: "admin",
    component: DashboardLayout,
    redirect: "/home",
    meta: {
      middleware: [AuthMiddleware],
    },
    children: [
      {
        name: "system-information",
        path: "system-information",
        meta: {
          subject: "system-information",
        },
        component: () =>
          import("@/pages/systeminformation/SystemInformation.vue"),
      },
      {
        name: "users-list",
        path: "users",
        meta: {
          subject: "users",
        },
        component: () => import("@/pages/admin/ListUser.vue"),
      },
      {
        name: "admin-pages",
        path: "",
        component: () => import("@/pages/users/user-page.vue"),
        children: [
          {
            name: "partners-list",
            path: "partners",
            meta: {
              subject: "partners",
            },
            component: () => import("@/pages/admin/partner/PartnerList.vue"),
          },
          {
            path: "lessons",
            children: [
              {
                name: "lessons",
                path: "",
                meta: {
                  subject: "lessons",
                },
                component: () => import("@/pages/admin/lesson/lesson-list.vue"),
              },
              {
                path: ":id",
                name: "lesson-info",
                meta: {
                  subject: "lessons",
                },
                component: () => import("@/pages/admin/lesson/lesson-page.vue"),
              },
            ],
          },
          {
            path: "tests",
            meta: {
              subject: "tests",
            },
            children: [
              {
                name: "tests",
                path: "",

                component: () => import("@/pages/admin/test/TestList.vue"),
              },
              {
                path: ":id",
                name: "test-info",
                component: () => import("@/pages/admin/test/TestPage.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "courses",
        children: [
          {
            name: "courses-list",
            path: "",
            meta: {
              subject: "courses",
            },
            component: () => import("@/pages/admin/course/CourseList.vue"),
          },
          {
            path: ":id",
            name: "courses-info",
            meta: {
              subject: "courses",
            },
            component: () => import("@/pages/admin/course/CoursePage.vue"),
          },
        ],
      },
      {
        path: "partners",
        children: [
          {
            name: "partners-list",
            path: "",
            meta: {
              subject: "partners",
            },
            component: () => import("@/pages/admin/partner/PartnerList.vue"),
          },
          {
            path: ":id",
            name: "partners-info",
            meta: {
              subject: "partners",
            },
            component: () => import("@/pages/admin/partner/partner-page.vue"),
          },
        ],
      },
      {
        name: "departments",
        path: "departments",
        meta: {
          subject: "departments",
        },
        component: () => import("@/pages/departments/department-page.vue"),
      },
      {
        name: "job-titles",
        path: "job-titles",
        meta: {
          subject: "job_titles",
        },
        component: () => import("@/pages/jobs/job-page.vue"),
      },
      {
        path: "groups",
        children: [
          {
            name: "groups",
            path: "",
            meta: {
              layoutClass: "role group-role",
              subject: "groups",
            },
            component: () => import("@/pages/role/groups-role.vue"),
          },
          {
            path: ":id",
            name: "role-info",
            meta: {
              layoutClass: "role group-role",
              subject: "groups",
            },
            component: () => import("@/pages/role/role-page.vue"),
          },
        ],
      },

      {
        path: "questions",
        children: [
          {
            name: "questions-list",
            path: "",
            meta: {
              subject: "questions",
            },
            component: () => import("@/pages/question/question-list.vue"),
          },
          {
            path: ":id",
            name: "question-info",
            meta: {
              subject: "questions",
            },
            component: () => import("@/pages/question/question-page.vue"),
          },
        ],
      },
      {
        name: "topics",
        path: "topics",
        meta: {
          subject: "topics",
        },
        component: () => import("@/pages/topic/topic-page.vue"),
      },
      {
        path: "workshops",
        children: [
          {
            name: "workshops-list",
            path: "",
            meta: {
              subject: "workshops",
            },
            component: () => import("@/pages/admin/workshop/WorkshopList.vue"),
          },
          {
            path: ":id",
            name: "workshop-info",
            meta: {
              subject: "workshops",
            },
            component: () => import("@/pages/admin/workshop/WorkshopPage.vue"),
          },
        ],
      },

      {
        path: "promotions",
        children: [
          {
            name: "promotions-list",
            path: "",
            meta: {
              subject: "promotions",
            },
            component: () =>
              import("@/pages/admin/promotion/PromotionPage.vue"),
          },
          {
            path: ":id",
            name: "promotion-info-page",
            meta: {
              subject: "promotion",
            },
            component: () =>
              import("@/pages/admin/promotion/PromotionInfoPage.vue"),
          },
        ],
      },

      {
        path: "inventories",
        children: [
          {
            name: "inventories",
            path: "",
            meta: {
              subject: "inventories",
            },
            component: () =>
              import("@/pages/admin/inventory/InventoryPage.vue"),
          },
        ],
      },

      {
        path: "kpi-dashboard",
        children: [
          {
            name: "kpi-dashboard",
            path: "",
            meta: {
              subject: "kpi-dashboard",
            },
            component: () =>
              import("@/pages/admin/kpi/dashboard/DashboardPage.vue"),
          },
        ],
      },

      {
        path: "kpis",
        children: [
          {
            name: "kpis-list",
            path: "",
            meta: {
              subject: "kpis",
            },
            component: () => import("@/pages/admin/kpi/KpiList.vue"),
          },
        ],
      },

      {
        path: "kpi-users",
        children: [
          {
            name: "kpi-users-list",
            path: "",
            meta: {
              subject: "kpi-users",
            },
            component: () => import("@/pages/admin/kpi/KpiUser.vue"),
          },
        ],
      },

      {
        path: "kpi-calculating",
        children: [
          {
            name: "kpi-calculating",
            path: "",
            meta: {
              subject: "kpis",
            },
            component: () => import("@/pages/admin/kpi/KpiCalculating.vue"),
          },
        ],
      },

      {
        path: "report",
        children: [
          {
            name: "report-promotion",
            path: "promotion",
            meta: {
              subject: "promotion",
            },
            component: () => import("@/pages/admin/report/tab/PromotionTab.vue")
          },
          {
            name: "report-inventory",
            path: "inventory",
            meta: {
              subject: "inventory"
            },
            component: () => import("@/pages/admin/report/tab/InventoryTab.vue")
          }
        ]
      },

      {
        path: "loggings",
        children: [
          {
            name: "loggings-list",
            path: "",
            meta: {
              subject: "loggings",
            },
            component: () => import("@/pages/admin/logging/LoggingList.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/about",
    name: "root",
    redirect: "/test",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../layouts/dashboard-layout.vue"),
    children: [
      {
        name: "about",
        path: "/test",
        component: () => import("../views/AboutView.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "layout-default",
    component: LayoutDefaut,
    children: [
      {
        path: "change-password",
        name: "change-password",
        meta: {
          layoutClass: "change-password",
          middleware: [AuthMiddleware],
          subject: "courses",
        },
        component: () =>
          import(
            /* webpackChunkName: "change-password" */ "@/views/change-password.vue"
          ),
      },
      {
        path: "login",
        name: "login",
        meta: {
          layoutClass: "login",
        },
        component: () =>
          import(/* webpackChunkName: "login" */ "@/views/login.vue"),
      },

      {
        name: "verify-user",
        path: "verify-user",
        component: () => import("@/pages/home/UserInfo.vue"),
      },
      // {
      //   path: "/about",
      //   name: "about",
      //   // route level code-splitting
      //   // this generates a separate chunk (about.[hash].js) for this route
      //   // which is lazy-loaded when the route is visited.
      //   component: () =>
      //     import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
      // },
    ],
  },
  {
    path: "/404",
    name: "PageNotExist",
    component: () => import("@/pages/result/NotFoundPage.vue"),
  },
  {
    path: "/403",
    name: "PageNotAuth",
    component: () => import("@/pages/result/AuthorizedPage.vue"),
  },
  {
    path: "/500",
    name: "ServerErrorPage",
    component: () => import("@/pages/result/ServerErrorPage.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import axios from "axios";
import ErrorHandler from "./ErrorResponseHandle";
import Constant from "@/enums/constant";
const baseDomain = process.env.API_URL || "http://localhost:3979";
const baseURL = `${baseDomain}`;
const vuex = JSON.parse(localStorage.getItem("vuex"));
const accessToken = vuex && vuex.authGuard ? vuex.authGuard.accessToken : "";
const request = axios.create({
  baseURL, // api_base_url
  mode: "no-cors",
  timeout: 20000, // request timeout
  headers: {
    "x-product": "TEST",
    "access-token": accessToken ? accessToken : "",
    platform: Constant.PLATFORM,
  },
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    ErrorHandler(error);
  }
);

export default request;
